.shopping-cart-order-list {
    width: calc(100% - 516px);
    background-color: #FFF;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: fit-content;
    min-width: 250px;
    max-width: 624px;
    max-height: calc(100vh - 319px);
    overflow-y: auto;
}

.shopping-cart-order-list .order_from {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  column-gap: 8px;
}

.shopping-cart-order-list .order_from__title {
    font-family: 'Barmeno', sans-serif !important;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
}

.shopping-cart-order-list .order_from__restaurant_name {
    font-family: 'Barmeno', sans-serif !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    color: #000000;
}

.shopping-cart-order-list .item_details {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
    border-bottom: 1px solid #D4D4D4;
}

.shopping-cart-order-list .item_details .spice {
    font-family: 'Barmeno', sans-serif !important;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
    color: #909090;
}

.shopping-cart-order-list .item_details .spice span {
    font-family: 'Barmeno', sans-serif !important;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.5;
    color: #1D1D1D;
}

.capitalize-text {
    text-transform: capitalize;
  }

.shopping-cart-order-list .amount {
    font-family: 'Barmeno', sans-serif !important;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    color: #000000;
}

.mr-lf-auto {
    margin: 0 0 0 auto !important;
}

.edit_button {
    width: 44px;
    height: 44px;
    padding: 6px 4px 6px 4px !important;
    gap: 6px;
    border-radius: 8px !important;
    background-color: #FAF4F4 !important;
    box-shadow: 0px 8px 32px 0px #0000000F;
    margin: 0 0 0 auto !important;
}

.review_cart_container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 100px 100px 80px 100px;
    font-family: 'Barmeno',sans-serif;
}

.review_order_iconbutton_div{
    border-radius: 50%;
  background-color: #9EBDA3;
  display: flex;
  flex-direction: column;
  padding: 6px;
  box-sizing: border-box;
}
.review_button_container_div{
    display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Verona",sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: #334155;
  position: relative;
  width: 40px;
  height: 40px;
  justify-content: center;
  border-radius: 50%;
}
.progess_bar_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}
.straight_green_line{
    height: 2px;
  background-color: #9EBDA3;
  width: 296px;
}
.wingos_cart_heading{
    font-family: "Verona",sans-serif;
  font-size: 36px;
  font-weight: 400;
  color: #2C6F37;
}
.wingos_cart_container{
    display: flex;
  flex-direction: column;
  gap: 24px;
}
.item_information_menu_option_container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 18px;
    font-weight: 700;
}
.item_description_cart_container{
flex-grow: 1.7;
display: flex;
flex-direction: column;
gap: 40px;
max-width: 781px;
}
.item_description_order_summary_container{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.item_description_div{
display: flex;
  padding: 16px 12px;
  gap: 16px;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  font-weight: 700;
}
.subTotalKey{
    color: #747474;
    font-size: 18px;
}
.subTotalValue{
    color: #1D1D1D;
    font-size: 20px;
}
.subTotalSummary{
    display: flex;
  flex-direction: column;
  gap: 16px;
}
.subTotalEachItem{
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.savings{
    font-weight: 500;
  color: #2C6F37;
  font-size: 16px;
}
.ConfirmOrderButtonReviewOrder{
    color: #fff !important;
    background-color: #E11B22 !important;
    height: 56px;
    padding: 0px !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}
.ConfirmOrderButtonReviewOrderDisabled{
  color: #909090 !important;
  background-color: #BFBFBF !important;
  height: 56px;
  padding: 0px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}
.shoppingCartMessage{
  padding: 8px;
  background-color: #F8FAFC;
  border-radius: 4px;
  color: #1D1D1D;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items:center;
}
.minimumCartValue{
  padding: 12px 0px 12px 16px;
  box-sizing: border-box;
  color: #E42328;
  background-color: #FEE2E2;
  border-left: 4px solid #E42328;
  font-weight: 700;
}
@media (max-width:950px) {
  .item_main_container_price{
    display: flex;
    gap: 24px;
    flex-direction: column;
  }
}
.item_main_container_price{
  display: flex;
  gap: 24px;
}
.container_to_item_number_price{
text-wrap: nowrap;
}

.allergen_exclusion_info{
  font-size: 14px;
    color: #E11B22;
    font-weight: 500;
    font-family: Barmeno, sans-serif;
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
}